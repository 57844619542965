import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { SectionWrapper } from "../components/styles/Sections"
import Footer from "../components/footer"

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <SectionWrapper>
        <Seo title="404: Not Found" />
        <h1>Ops! Sidan du letar efter verkar inte finnas</h1>
        <p>Vänligen sök efter det du letar efter</p>
      </SectionWrapper>
      <Footer />
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
