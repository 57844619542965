import React from "react";
import styled from "styled-components";
import { Link, graphql, useStaticQuery } from "gatsby";
import Icon from "./Icon";
import Scroll from "./scrollToTop";
import { StaticImage } from "gatsby-plugin-image";
import loadable from "@loadable/component";

const CookieConsent = loadable(() =>
  import(/* webpackPrefetch: true */ "react-cookie-consent")
);

const Footer = () => {
  const menus = useStaticQuery(footerQuery);

  const siteLinks = menus.allWpMenu.edges.filter(
    (menu) => menu.node.locations[0] === "MENU_3"
  );
  const primaryMenu = menus.allWpMenu.edges.filter(
    (menu) => menu.node.locations[0] === "MENU_2"
  );

  const reviews = menus.allWpPost.nodes;
  const year = new Date().getFullYear();

  return (
    <FooterWrapper>
      <CookieConsent
        location="bottom"
        buttonText="Jag förstår"
        cookieName="gatsby-gdpr-google-tagmanager"
      >
        Vi använder oss av cookies för att förbättra användarupplevelsen på vår
        webbplats.
      </CookieConsent>
      <Scroll showBelow={500} />
      <Container>
        <div>
          <span>Sidlänkar</span>
          <ul>
            {siteLinks[0].node.menuItems.nodes.map((item) => (
              <li key={item.id}>
                <Link to={item.url}>{item.label}</Link>
              </li>
            ))}
          </ul>
        </div>
        <div>
          <span>Populära kategorier</span>
          <ul>
            {primaryMenu[0].node.menuItems.nodes.map((item) => (
              <li key={item.id}>
                <Link to={item.url}>{item.label}</Link>
              </li>
            ))}
          </ul>
        </div>
        <div>
          <span>Nya recensioner</span>
          <ul>
            {reviews.map((item) => (
              <li key={item.id}>
                <Link to={item.uri}>{item.title}</Link>
              </li>
            ))}
          </ul>
        </div>
        <div>
          <span>Om oss</span>
          <p>
            Svenskaonlinecasinon.se är en casino guide som skapades av tre
            vänner med ett stort intresse för spel. Tanken med sidan är att ge
            våra besökare så mycket information och hjälp som möjligt när det
            kommer till casino online. Vi vill också vara transparenta med att
            vi får betalt för att marknadsföra våra partners.
          </p>
          <div className="socials">
            <span>Sociala kanaler:</span>
            <a
              href="https://www.facebook.com/Svenskaonlinecasinonse-104214472168978"
              rel="nofollow noreferrer noopener"
              target="_blank"
              aria-label="Facebook"
            >
              <Icon icon="facebook1" size={28} />
            </a>
            <a
              href="https://www.twitch.tv/svenskaonlinecasinon"
              rel="nofollow noopener noreferrer"
              aria-label="Twitch"
              target="_blank"
            >
              <Icon className="twitch-icon" icon="twitch" size={25} />
            </a>
            <a
              href="https://twitter.com/svenskaonline24"
              aria-label="Twitter"
              rel="nofollow noopener noreferrer"
              target="_blank"
            >
              <Icon className="twitter" icon="twitter" size={25} />
            </a>
          </div>
        </div>
      </Container>
      <ResponsibleGaming>
        <div className="logos">
          <StaticImage
            className="spelinspektionen"
            alt="spelinspektionen"
            width={80}
            height={56}
            src="../spelinspektionen_logotyp.webp"
          />
          <a
            href="https://stodlinjen.se/#!/"
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            <StaticImage
              alt="stodlinjen"
              className="stodlinjen"
              width={250}
              height={56}
              src="../stodlinjen.webp"
            />
          </a>
          <StaticImage
            className="pluslogo"
            alt="18plus"
            width={60}
            height={60}
            src="../18plus-logo.webp"
          />
          <a
            href="https://www.spelpaus.se/"
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            <StaticImage
              className="spelpaus"
              alt="spelpaus"
              width={190}
              height={55}
              src="../spelpaus.webp"
            />
          </a>
        </div>
        <p>
          Känner du att du spelar för mycket? Ring stödlinjen på 020-81 91 00
        </p>
      </ResponsibleGaming>
      <p className="copyright">
        © {year} svenskaonlinecasinon.se • Alla rättigheter reserverade.
      </p>
    </FooterWrapper>
  );
};

export default Footer;

export const footerQuery = graphql`
  query {
    allWpMenu(filter: { locations: { in: [MENU_3, MENU_2] } }) {
      edges {
        node {
          locations
          menuItems {
            nodes {
              label
              url
              id
              cssClasses
            }
          }
        }
      }
    }
    allWpPost(limit: 6, sort: { fields: [date], order: DESC }) {
      nodes {
        title
        uri
        id
      }
    }
  }
`;

const FooterWrapper = styled.div`
  background: var(--color-blue);

  .copyright {
    text-align: center;
    color: #f5f5f5;
    font-size: 14px;
    margin: 0;
    padding: 15px;
  }

  .co-neutral {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }
`;

const Container = styled.div`
  max-width: var(--container);
  margin: 0 auto;
  display: grid;
  grid-gap: 25px;
  box-sizing: border-box;
  padding: 50px 20px;
  color: #f5f5f5;
  flex-direction: column;
  justify-content: space-around;
  grid-template-columns: 0.35fr 0.6fr 0.5fr 1fr;
  border-bottom: 1px solid #11207c;

  @media (max-width: 992px) {
    grid-template-columns: 1fr;
    padding: 50px 25px;
  }

  span {
    color: #f5f5f5;
    font-weight: 600;
    font-size: 20px;
    display: block;
    margin-bottom: 15px;
  }

  p {
    line-height: 1.7em;
    color: #f5f5f5;
    font-size: 14px;
    margin-bottom: 20px;
  }

  .socials {
    display: flex;
    grid-gap: 15px;
    align-items: center;

    span {
      font-size: 14px;
      margin-bottom: 8px;
      font-weight: 500;
      color: #f5f5f5;
    }

    svg {
      color: var(--color-yellow);
    }

    .twitch-icon {
      position: relative;
      top: 2px;
    }

    .twitter {
      margin-left: 2px;
    }
  }

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
      padding: 0;
      margin: 10px 0;

      a {
        font-size: var(--fontSize-0);
        color: #fff;
        text-decoration: none;

        &:hover {
          color: var(--color-yellow);
        }
      }
    }
  }

  .language {
    margin-top: 25px;

    li {
      display: flex;
      align-items: center;

      img {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
    }
  }
`;

const ResponsibleGaming = styled.div`
  p {
    color: #f5f5f5;
    font-size: 14px;
    text-align: center;
    padding: 0 20px;
  }

  .logos {
    margin: 0 auto;
    padding: 55px 0 25px;
    max-width: 400px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 20px;
    align-items: center;
    justify-content: center;

    @media (max-width: 600px) {
      max-width: 200px;
      grid-template-columns: 1fr 1fr;
    }

    img {
      height: auto;
    }

    .spelinspektionen {
      width: 40px;
    }

    .stodlinjen {
      width: 125px;
      position: relative;
      top: 4px;
    }

    .pluslogo {
      max-width: 30px;
      filter: brightness(0) invert(1);
    }

    .spelpaus {
      width: 95px;
      border-radius: 4px;
    }
  }
`;
