import React, { useState, useEffect } from 'react'
import styled from "styled-components";
import Icon from './Icon';

const Scroll = ({
  showBelow,
}) => {

  const [show, setShow] = useState(showBelow ? false : true)

  const handleScroll = () => {
    if (window.pageYOffset > showBelow) {
      if (!show) setShow(true)
    } else {
      if (show) setShow(false)
    }
  }

  const handleClick = () => {
    window[`scrollTo`]({ top: 0, behavior: `smooth` })
  }

  useEffect(() => {
    if (showBelow) {
      window.addEventListener(`scroll`, handleScroll)
      return () => window.removeEventListener(`scroll`, handleScroll)
    }
  })

  return (
    <div>
      {show &&
        <ScrollButton onClick={handleClick} aria-label="to top" component="span">
          <Icon icon="arrow-up" size={20} />            
        </ScrollButton>
      }
    </div>
  )
}
export default Scroll

const ScrollButton = styled.button`
  position: fixed;
  bottom: 25px;
  right: 25px;
  -webkit-appearance: none;
  cursor: pointer;
  background: var(--color-blue-dark);
  border: 0;
  color: var(--color-yellow);
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
`;